import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`O firme`}</h2>
    <p>{`Sme rodinnou stolárskou firmou zaoberajúcou sa zákazkovou výrobou nábytku na mieru. Na slovenskom trhu pôsobíme od roku 1992. Počas tohto dlhého obdobia sme získali bohaté skúsenosti. Sme schopní zrealizovať každú požiadavku zákazníka k najväčšej spokojnosti a kvalite. Našou devízou
je možnosť vyhotovenia atypického nábytku. Pracujeme s každým druhom dreva v akejkoľvek povrchovej úprave. V našom záujme je kvalita, spoľahlivosť a spokojnosť zákazníka.`}</p>
    <br />
    <img src="/visk_building.jpg" alt="Prevádzka" />
    <br />
    <h3>{`Ponuka výrobkov`}</h3>
    <ul>
      <li parentName="ul">{`Kuchynské linky so zabezpečením kompletného vybavenia aj so spotrebičmi na želanie klienta`}</li>
      <li parentName="ul">{`Interiérové a exteriérové dvere so zárubňami`}</li>
      <li parentName="ul">{`Schodiská a obklady schodov`}</li>
      <li parentName="ul">{`Eurookná`}</li>
      <li parentName="ul">{`Interiérový nábytok – obývacie izby, detské izby, spálne`}</li>
      <li parentName="ul">{`Jedálenské stoly, konferenčné stolíky`}</li>
      <li parentName="ul">{`Vstavané skrine`}</li>
      <li parentName="ul">{`Kúpeľňové zostavy`}</li>
      <li parentName="ul">{`Chodbové zostavy`}</li>
      <li parentName="ul">{`Kancelárske nábytky`}</li>
      <li parentName="ul">{`Záhradné altánky a prístrešky`}</li>
      <li parentName="ul">{`Interiéry obchodov, lekární, ordinácií, múzeí, kaviarní, barov, klubov...`}</li>
    </ul>
    <h2>{`Referencie`}</h2>
    <ul>
      <li parentName="ul">{`MILK – AGRO spol. s r.o.`}</li>
      <li parentName="ul">{`Interiér Invest s.r.o.`}</li>
      <li parentName="ul">{`MODES – Igor Melicherčík`}</li>
      <li parentName="ul">{`RONO FOOD spol. s r.o.`}</li>
      <li parentName="ul">{`CLIOS s.r.o.`}</li>
      <li parentName="ul">{`RETRA s.r.o.`}</li>
      <li parentName="ul">{`GMT Olus, s.r.o.`}</li>
      <li parentName="ul">{`TECHNOINVEST s.r.o.`}</li>
      <li parentName="ul">{`Mestské lesy Košice a.s.`}</li>
      <li parentName="ul">{`RAFFAELIS IDE s.r.o.`}</li>
      <li parentName="ul">{`MUDr. Ivan Dzúr`}</li>
      <li parentName="ul">{`LEŠENIE s.r.o.`}</li>
      <li parentName="ul">{`MIKO – OS s.r.o.`}</li>
      <li parentName="ul">{`IDEAS inex`}</li>
      <li parentName="ul">{`Tofako plus s.r.o.`}</li>
      <li parentName="ul">{`Cardio4life s.r.o.`}</li>
      <li parentName="ul">{`Východoslovenské Múzeum Košice`}</li>
      <li parentName="ul">{`Ústavný súd Slovenskej republiky`}</li>
    </ul>
    <h2>{`Kontakt`}</h2>
    <img src="/visk_map.png" alt="Mapa" />
    <p><strong parentName="p">{`Názov firmy:`}</strong><br />{`
Vladimír Kasarda - VISK`}</p>
    <p><strong parentName="p">{`Sídlo firmy:`}</strong><br />{`
Vladimír Kasarda - VISK`}<br />{`
Národná trieda 39`}<br />{`
040 01 Košice`}<br /></p>
    <p><strong parentName="p">{`Adresa prevádzky:`}</strong><br />{`
Tešedíkova 60`}<br />{`
040 17 Košice`}<br /></p>
    <p>{`Telefón/fax: +421 55 6321 772`}<br />{`
Mobil: +421 910 433 640`}<br />{`
e-mail: `}<a parentName="p" {...{
        "href": "mailto:visk.drevo@gmail.com"
      }}>{`visk.drevo@gmail.com`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      